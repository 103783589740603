
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import Showcase from "./components/showcase/Showcase";
import { Routes, Route } from "react-router-dom";
import Articles from "./articles";
import ArticleMain from "./components/articles/ArticleMain";

function App() {

  const allArticles = Articles.list;

  return (
    <div className="App">
      <Header />
        <Routes>
          <Route exact path="/"  element={<Showcase articles={allArticles}/>}/>
          <Route exact path="/article/:post/:title" element={<ArticleMain articles={allArticles}/>} />
        </Routes>
      <Footer />
    </div>
  );
}

export default App;
