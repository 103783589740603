import React from 'react'
import { Link } from 'react-router-dom';

const NewArticles = (props) => {
  const articles = props.articles;
  return (
    <div>
      <div className='lrg-title'>Latest Articles</div>
      <div className='new-wrap'>
        {
          articles.map((post) => (
            <div key={post.id} className='new-card'>
              <div>
                <Link to={`/article/${post.id}/${post.lug}`}>
                  <img className='post-img' src={post.image_url} alt="" />
                </Link>
              </div>
              <div className='med-title'>
                <Link to={`/article/${post.id}/${post.lug}`}>
                  <strong>{post.title}</strong>
                </Link>
                
              </div>
              <div>{post.description}</div>
            </div>
          ))
        }
      </div>
    </div>
  )
}

export default NewArticles