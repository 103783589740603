import React from 'react'

const Menu = () => {
  return (
    <div className='menu'>
        {/* <div>
            <a href="">Games</a>
        </div>
        <div>
            <a href="">Products</a>
        </div> */}
    </div>
  )
}

export default Menu