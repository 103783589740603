import logo from "../../img/logo.png"
import "./header.css"
import Menu from "./Menu"

const Header = () => {
  return (
    <div className="header">
      <div>
        <a href="/">
          <img className="logo-img" src={logo}></img>
        </a>
      </div>
      <Menu />
    </div>
  )
}

export default Header