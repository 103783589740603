import React from 'react'
import "./footer.css"

const Footer = () => {
  return (
    <div className='footer'>
        <div className='copyright'>
            &copy; 2022 VRFBets.com
        </div>
        
    </div>
  )
}

export default Footer