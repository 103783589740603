import React from 'react'
import NewArticles from '../new/New'
import "./showcase.css"
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
const Showcase = (props) => {
    const articles = props.articles;
    const [featured, setFeatured] = useState("")

    useEffect(() => {
      const feat = getFeatured();
      setFeatured(feat);
    }, [articles])
    
    const getFeatured = () => {
        const total = articles.length;
        if(total > 1){
            const randomNum = Math.floor(Math.random() * total);
            return articles[randomNum]
        }else{
            return articles[0]
        }
    }

    return (
        <div>
            <div className='hero'>
                <div className='hero-inner'>
                    <div className='content-wrap'>
                        <div className='hero-title'>
                            Discover Casino Games and Products!
                        </div>
                        <div className='hero-desc'>
                            {featured.description}
                        </div>
                        <div className='hero-btn-wrap'>
                            <Link to={`/article/${featured.id}/${featured.lug}`} className='hero-btn'>{featured.title}</Link>
                        </div>
                    </div>    
                </div>
            </div>
            <div>
                <NewArticles articles={articles}/>
            </div>
        </div>
    )
}

export default Showcase