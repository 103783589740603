import React from 'react'
import "./article.css"
import ArticleContent from './ArticleContent'
const ArticleMain = (props) => {

  const path = window.location.pathname
  const pathData = path.split("/").filter((e)=> {return((e != '') && (e != 'article'))})
  const articleID = pathData[0]
  const articleLug = pathData[1]
  const allArticles = props.articles
  const result = allArticles.filter((post) => {
      if(post.id.toString() === articleID){
          return post
      }
  })
  const article = result[0]

  return (
    <div className='article'>
      <div className='article-img-wrap'>
          <img src={article.image_url} className='article-img' />
      </div>
      <div className='lrg-title'>{article.title}</div>
      <div className='med-title'>{article.description}</div>
      <div className='main-blob'>{article.main_blob}</div>
      <ArticleContent id={articleID} article={article}/>
    </div>
  )
}

export default ArticleMain