import React from 'react'

const Top3PlayingCards = (props) => {
    return (
        <div>
            <div className="article-card">
                <div className='med-title'><small>1.</small> Bicycle Standard Face Playing Cards</div>
                <div>
                    <img className='item-img' src="https://m.media-amazon.com/images/I/711TbIZxM9L._AC_SX679_.jpg" alt="" />
                </div>
                <div className='item-blob'>
                    Bicycle Playing Cards are the best
                </div>
                <div className='button-wrap'>
                    <div className='amzn-btn-wrap' >
                        <a className='button' href="https://amzn.to/3wTVtoa">Buy Amazon US</a>
                    </div>
                    <div className='amzn-btn-wrap' >
                        <a className='button' href="https://amzn.to/3GprTeL">Buy Amazon CAD</a>
                    </div>
                </div>
            </div>
            <div className="article-card">
                <div className='med-title'><small>2.</small> Teskyer Large Print Playing Cards</div>
                <div>
                    <img className='item-img' src="https://m.media-amazon.com/images/I/71i7OhA-pRL._AC_SX679_.jpg" alt="" />
                </div>
                <div className='item-blob'>
                    Teskyer Large Print Playing Cards are next best
                </div>
                <div className='button-wrap'>
                    <div className='amzn-btn-wrap' >
                        <a className='button' href="https://amzn.to/3t3p6lV">Buy Amazon US</a>
                    </div>
                    <div className='amzn-btn-wrap' >
                        <a className='button' href="https://amzn.to/3lJzICB">Buy Amazon CAD</a>
                    </div>
                </div>
            </div>
            <div className="article-card">
                <div className='med-title'><small>3.</small> LotFancy Playing Cards</div>
                <div>
                    <img className='item-img' src="https://m.media-amazon.com/images/I/81uwiRzDISL._AC_SX679_.jpg" alt="" />
                </div>
                <div className='item-blob'>
                    LotFancy Playing Cards are good
                </div>
                <div className='button-wrap'>
                    <div className='amzn-btn-wrap' >
                        <a className='button' href="https://amzn.to/3Gve9iz">Buy Amazon US</a>
                    </div>
                    <div className='amzn-btn-wrap' >
                        <a className='button' href="https://amzn.to/3GlHSu8">Buy Amazon CAD</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Top3PlayingCards