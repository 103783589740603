import React from 'react'

const Top3PokerTables = () => {
    return (
        <div>
            <div className="article-card">
                <div className='med-title'><small>1.</small> Oval Texas Holdem Folding Poker Table</div>
                <div>
                    <img className='item-img' src="https://m.media-amazon.com/images/I/61aJbGRIylL._AC_SX679_.jpg" alt="Oval Texas Holdem Folding Poker Table" />
                </div>
                <div className='item-blob'>
                Thickened, waterproof, flame retardant table mat, equipped with 10 Cup holders, made with premium polyester material to be truly spill-proof and stain-resistant will making you feel comfortable and not tired after sitting for a long time. <br /> Measuring 84 x 42 inches , The perfect large size is very suitable for your desktop and support for multiplayer use. You can play card games with your friends and its large size ensures that no one is excluded from the game
                </div>
                <div className='button-wrap'>
                    <div className='amzn-btn-wrap' >
                        <a className='button' href="https://amzn.to/38nO6gB">Buy Amazon US</a>
                    </div>
                    <div className='amzn-btn-wrap' >
                        <a className='button' href="https://amzn.to/3MR8RQT">Buy Amazon CAD</a>
                    </div>
                </div>
            </div>
            <div className="article-card">
                <div className='med-title'><small>2.</small> Triton Poker Folding Poker Table Casino Style</div>
                <div>
                    <img className='item-img' src="https://m.media-amazon.com/images/I/81gkwCj9JcL._AC_SX466_.jpg" alt="Triton Poker Folding Poker Table Casino Style" />
                </div>
                <div className='item-blob'>
                The design of the poker table lets you fold up and fold out the poker table for storage. The table is foldable, which allows you to take it with you to a party or friend’s house easily. Great for Texas Holdem games and blackjack. <br/> The oval octagon shape design allows for 8 and 10 players. Built-in cup holders keep sweaty glasses and bottles off of the tables felt surface. Features interchangeable Mats you can purchase separately! 
                </div>
                <div className='button-wrap'>
                    <div className='amzn-btn-wrap' >
                        <a className='button' href="https://amzn.to/3GlwHlr">Buy Amazon US</a>
                    </div>
                    <div className='amzn-btn-wrap' >
                        <a className='button' href="https://amzn.to/3wQAHHk">Buy Amazon CAD</a>
                    </div>
                </div>
            </div>
            <div className="article-card">
                <div className='med-title'><small>3.</small> Oggo Texas Holdem Poker Table</div>
                <div>
                    <img className='item-img' src="https://m.media-amazon.com/images/I/61YAMBwYWmL._AC_SX679_.jpg" alt="Oggo Texas Holdem Poker Table" />
                </div>
                <div className='item-blob'>
                Rubber Foam Table Top with elaborate designs. The cards slide easily. which provides a smooth and non-resistance surface for card games.<br /> Thickened, waterproof, flame retardant it also protects the table from scratches and stains. Measuring 84 x 42 inches, table legs can be folded, you can play card games with your friends everywhere
                </div>
                <div className='button-wrap'>
                    <div className='amzn-btn-wrap' >
                        <a className='button' href="https://amzn.to/3Gve9iz">Buy Amazon US</a>
                    </div>
                    <div className='amzn-btn-wrap' >
                        <a className='button' href="https://amzn.to/3GlHSu8">Buy Amazon CAD</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Top3PokerTables