import React from 'react'
import Top3PlayingCards from './content/Top3PlayingCards';
import Top3PokerTables from './content/Top3PokerTables';
const ArticleContent = (props) => {
    const id = props.id;
    const post = props.article;
    if(id==="1"){
        return <Top3PlayingCards article={post}/>
    }else if(id==="2"){
        return <Top3PokerTables article={post} />
    }
    return(
        <div>Nothing</div>
    )
}

export default ArticleContent